import validate from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45required_45global from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/middleware/auth-required.global.ts";
import _02_45route_45rules_45global from "/builds/rialtic/workflows/console-ui/apps/console/middleware/02.route-rules.global.ts";
import _03_45rbac_45global from "/builds/rialtic/workflows/console-ui/apps/console/middleware/03.rbac.global.ts";
import feature_45flags_45global from "/builds/rialtic/workflows/console-ui/apps/console/middleware/feature-flags.global.ts";
import manifest_45route_45rule from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45required_45global,
  _02_45route_45rules_45global,
  _03_45rbac_45global,
  feature_45flags_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "claim-lab": () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/middleware/claim-lab.ts"),
  "all-connectors": () => import("/builds/rialtic/workflows/console-ui/apps/console/middleware/all-connectors.ts"),
  "connector-required": () => import("/builds/rialtic/workflows/console-ui/apps/console/middleware/connector-required.ts"),
  "rialtic-admin": () => import("/builds/rialtic/workflows/console-ui/apps/console/middleware/rialtic-admin.ts"),
  "workspace-system-admin": () => import("/builds/rialtic/workflows/console-ui/apps/console/middleware/workspace-system-admin.ts")
}